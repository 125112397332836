<template>
  <div class="home_page">
    <van-popup
      v-model="show"
      position="top"
      v-if="tzInfo"
      round
      overlay-class="tzInfo-overlay"
    >
      <div class="more_info">
        <img
          :src="
            tzInfo.userDetailEntity.profilePicture
              ? tzInfo.userDetailEntity.profilePicture
              : require('@/assets/images/tx.png')
          "
          alt=""
        />
        <div class="user_info">
          <h3>{{ tzInfo.userDetailEntity.getGoodsName }}</h3>
          <h3>店　长：{{ tzInfo.userEntity.actualName }}</h3>
          <!-- <p>电话：{{ tzInfo.userEntity.telNum }}</p> -->
          <p>地址：{{ tzInfo.userDetailEntity.getGoodsAddress }}</p>
        </div>
      </div>
    </van-popup>
    <search :show="searchShow" @close="getSearchShow" />
    <div
      class="info"
      v-if="tzInfo || ($store.state.App && isLogin)"
      :style="bk ? `background:rgb(${bk})` : ' background: #1d4b89;'"
    >
      <span @click="show = true"
        >{{ tzInfo ? tzInfo.userDetailEntity.getGoodsName : ""
        }}<svg-icon icon-class="home_more_icon" v-if="tzInfo"
      /></span>
      <span class="switch" @click="switchAddress" v-if="$store.state.App"
        ><svg-icon icon-class="address_icon2" />切换</span
      >
    </div>
    <van-sticky class="search">
      <van-search
        v-model="search"
        shape="round"
        placeholder="请输入名称、货号、规格、品牌搜索"
        :clearable="false"
        show-action
        @focus="searchShow = true"
        :class="topDistance > 0 ? '' : 'van-search2'"
        :background="
          topDistance > 0 ? (bk ? `rgb(${bk})` : '#1d4b89') : '#f5f6fa'
        "
      >
        <template #left-icon>
          <svg-icon icon-class="home_nav_search_icon" />
        </template>
        <template #action>
          <svg-icon
            icon-class="home_nav_message_icon"
            @click="$router.push('/news')"
          />
        </template>
      </van-search>
    </van-sticky>
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      success-text="刷新成功"
    >
      <div
        class="background_top p_0_15"
        :style="
          bk
            ? `background: linear-gradient(180deg,rgb(${bk}) 0%,#fff 40%,#fff 70%,#f5f6fa 100%)`
            : 'background: linear-gradient(180deg,#1d4b89 0%,#fff 40%,#fff 70%,#f5f6fa 100%)'
        "
      >
        <van-swipe
          :autoplay="topDistance > 0 ? 6500 : 0"
          class="banner"
          @change="bannerChange"
        >
          <van-swipe-item
            v-for="(image, index) in banner"
            :key="index"
            @click="skip(image)"
          >
            <!-- :style="`background-image:url(${image.picPosition})`" -->
            <img
              v-lazy="image.picPosition"
              :id="`bannerImg${index}`"
              crossorigin="anonymous"
            />
          </van-swipe-item>
        </van-swipe>
        <div ref="bannerBottom"></div>
        <div class="live_broadcast" v-if="LiveInfo">
          <div class="title">
            <h5>正品汇直播</h5>
            <span>本场开播 {{ LiveInfo.startTime }}</span>
            <a>更多<svg-icon icon-class="arrow_right" /></a>
          </div>
          <div class="content">
            <div class="tips" v-if="LiveInfo.liveStatus">
              <img src="@/assets/images/zf_font.png" />
              <img src="@/assets/images/live.gif" />
              <span>直播中</span>
            </div>
            <van-empty
              description=""
              image-size="100"
              v-if="!LiveInfo.items.length"
              style="width: 100%; padding: 22px 0"
            />
            <div
              class="goods"
              v-for="(item, index) in LiveInfo.items"
              :key="index"
              v-else
            >
              <img v-lazy="item.filePath" />
              <p class="price"><span>￥</span>{{ item.price.toFixed(2) }}</p>
              <p class="original_price">
                <svg-icon icon-class="home_q_icon" /><span
                  >￥{{ item.retailPrice.toFixed(2) }}</span
                >
              </p>
            </div>
          </div>
          <a :href="URLSchema" v-if="URLSchema" class="URLSchema"></a>
          <!-- <wx-open-launch-weapp
            id="launch-btn"
            appid="wx90363dc11c1572c0"
            path="pages/index/index"
            v-else
          >
            <script type="text/wxtag-template">
              <style>.btn {padding:10% 0;width:100% ;background: pink }</style>
              <button class="btn"></button>
            </script>
          </wx-open-launch-weapp> -->
        </div>
        <div class="taste_price" v-if="tastePriceList.length">
          <img src="@/assets/images/taste_bg.png" class="bg" />
          <div class="title">
            <h5>新人尝鲜价</h5>
            <span>精选好货任选一件，仅限首单</span>
            <router-link to="/earlyAdopters"
              >更多<svg-icon icon-class="arrow_right"
            /></router-link>
          </div>
          <div class="content">
            <div
              class="goods"
              v-for="item in tastePriceList"
              :key="item.barcode"
              @click="
                $router.push({
                  path: '/goodsDetails',
                  query: {
                    barcode: item.barcode,
                    enterpriseId: item.enterpriseId,
                  },
                })
              "
            >
              <img v-lazy="item.filePath" />
              <p class="old_price">￥{{ item.retailPrice.toFixed(2) }}</p>
              <p class="price"><span>￥</span>{{ item.price.toFixed(2) }}</p>
            </div>
          </div>
        </div>
        <van-swipe
          class="category"
          :style="category.length > 1 ? 'padding-bottom: 10px;' : ''"
        >
          <van-swipe-item v-for="(list, i) in category" :key="i">
            <van-grid :column-num="5" :icon-size="55" :border="false">
              <van-grid-item
                v-for="item in list"
                :key="item.categoryId"
                :icon="item.categoryImage"
                :text="item.categoryName"
                @click="goCategory(item)"
              />
            </van-grid>
          </van-swipe-item>
        </van-swipe>

        <div class="time_limited_seckill" v-if="seckillList.length">
          <!-- v-if="seckillInfo.seckillEnableState < 2" -->

          <div class="title">
            <p>限时秒杀</p>
            <span>{{
              seckillInfo.seckillEnableState
                ? seckillInfo.seckillEnableState === 2
                  ? "已结束"
                  : "距离结束"
                : "距离开始"
            }}</span>
            <van-count-down
              v-if="seckillInfo.seckillEnableState !== 2"
              :time="
                seckillInfo.seckillEnableState
                  ? seckillInfo.seckillEndTime - new Date()
                  : seckillInfo.seckillStartTime - new Date()
              "
              @finish="finish"
              class="countdown"
            />
            <!-- <span class="countdown">03 : 58 : 02</span> -->
            <router-link to="/seckill"
              >更多<svg-icon icon-class="arrow_right"
            /></router-link>
          </div>
          <div class="content">
            <van-empty
              description=""
              image-size="100"
              v-if="!seckillGoodsList.length"
            />
            <div
              class="goods"
              v-for="(item, i) in seckillGoodsList"
              :key="i"
              @click="
                $router.push({
                  path: '/goodsDetails',
                  query: {
                    commId: item.commId,
                  },
                })
              "
              v-else
            >
              <img v-lazy="item.filePath" />
              <p class="price">
                <span>￥</span>{{ item.retailSeckillPrice.toFixed(2) }}
              </p>
              <p class="original_price">
                <svg-icon icon-class="home_q_icon" /><span
                  >￥{{ item.retailPrice.toFixed(2) }}</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="background_bottom p_0_15">
        <van-swipe :autoplay="0" class="bank_banner">
          <!-- v-if="!bankVip" -->
          <van-swipe-item>
            <img
              src="@/assets/images/integral_banner.gif"
              alt=""
              @click="
                $router.replace({
                  path: '/home/homePage',
                  query: {
                    id: $route.query.id ? $route.query.id : undefined,
                    integralBannerShow: 1,
                  },
                }),
                  (integralBannerShow = true)
              "
            />
          </van-swipe-item>
          <van-swipe-item
            v-for="(item, index) in bankBanner"
            :key="index"
            @click="toPolicy(item)"
          >
            <!-- :style="`background-image:url(${image.picPosition})`" -->
            <img :src="item.bannerIntegral" />
          </van-swipe-item>
        </van-swipe>
        <!-- <div class="integral_banner" v-else-if="isLogin"> -->
        <van-overlay :show="integralBannerShow" z-index="99999">
          <img src="@/assets/images/lqjfbg.png" alt="" />
          <div class="wrapper" @click.stop>
            <img src="@/assets/images/jb_icon.png" alt="" />
            <p>恭喜获得20000积分</p>
            <p>全场通用，下单抵扣</p>
            <div>
              <button @click="RegisteredBonusIntegral">开心收下</button
              ><button @click="viewIntegral">查看积分</button>
            </div>
          </div>
          <svg-icon
            icon-class="close_icon"
            @click="
              $router.replace({
                path: '/home/homePage',
                query: {
                  id: $route.query.id ? $route.query.id : undefined,
                  integralBannerShow: undefined,
                },
              }),
                (integralBannerShow = false)
            "
          />
        </van-overlay>
        <!-- </div> -->
        <div class="special_area">
          <div
            class="box"
            v-for="list in specialAreaList"
            :key="list.c"
            @click="$router.push(list.href)"
          >
            <div class="title">
              <span>{{ list.n }}</span>
              <span :style="{ color: list.c }">{{ list.t }}</span>
            </div>
            <van-loading v-if="!list.goods" />
            <van-swipe
              :autoplay="3000"
              :show-indicators="false"
              :touchable="false"
              v-else-if="list.goods.length"
            >
              <van-swipe-item v-for="(item, i) in list.goods" :key="i">
                <div v-for="img in item" :key="img">
                  <img v-lazy="img" />
                </div>
              </van-swipe-item>
            </van-swipe>
            <van-empty description="" image-size="70" v-else />
          </div>
          <div class="box" @click="$router.push('/community')">
            <div class="title">
              <span>社群福利</span>
              <span style="color: #f91c12">惊喜福利</span>
            </div>
            <img src="@/assets/images/sqfl_banner.png" style="width: 100%" />
          </div>
          <div class="sign_in">
            <div
              class="greatValueSpecial_box"
              @click="$router.push(greatValueSpecial.href)"
            >
              <span class="title">{{ greatValueSpecial.n }}</span>
              <van-loading v-if="!greatValueSpecial.goods" />
              <van-swipe
                :autoplay="3000"
                :show-indicators="false"
                :touchable="false"
                v-else-if="greatValueSpecial.goods.length"
              >
                <van-swipe-item
                  v-for="(item, i) in greatValueSpecial.goods"
                  :key="i"
                >
                  <img v-lazy="item" />
                </van-swipe-item>
              </van-swipe>
            </div>
            <div
              class="sign_in_box"
              @click="
                isLogin
                  ? $router.push({
                      path: '/userData/signIn',
                      query: {
                        automaticCheckIn: '1',
                      },
                    })
                  : $router.push('/login')
              "
            >
              <span class="title">每日签到</span>
              <div>
                <img src="@/assets/images/sign_in.png" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div class="activity">
          <div class="title">
            <p>主题专区<span>汇聚好物</span></p>
            <router-link to="/activity"
              >更多<svg-icon icon-class="arrow_right"
            /></router-link>
          </div>
          <div class="content">
            <img
              v-lazy="item.platformPic[0]"
              v-for="item in activity"
              :key="item.id"
              @click="
                $router.push({
                  path: '/activity/activityDetail',
                  query: {
                    activityId: item.id,
                  },
                })
              "
            />
          </div>
        </div>

        <van-tabs
          v-model="active"
          sticky
          offset-top="54"
          :animated="true"
          :swipeable="true"
          line-width="64"
          line-height="18"
          title-active-color="#F91C12"
          title-inactive-color="#333333"
          @change="tabChange"
        >
          <van-tab v-for="(list, i) in navList" :title="list.title" :key="i">
            <van-list
              v-model="list.loading"
              :finished="list.finished"
              :error.sync="list.error"
              error-text="请求失败，点击重新加载"
              @load="list.load"
              :offset="0"
              v-if="i === active"
            >
              <div class="goods_list" id="goods_list" v-if="i === 0 || i === 1">
                <goods
                  v-for="(item, index) in list.list"
                  :key="index"
                  :title="item.commodityName"
                  :goodsInfo="item"
                />
                <p
                  class="pull_up"
                  v-show="!(list.error || list.finished || list.loading)"
                >
                  上拉 查看更多推荐商品 <svg-icon icon-class="home_more_icon" />
                </p>
                <van-empty
                  description="暂无商品"
                  v-if="list.finished && !list.loading && !list.list.length"
                />
                <div v-show="list.finished && list.list.length" class="end">
                  <p @click="$router.push('/goodsList')">点击去查看所有商品</p>
                </div>
              </div>
              <!-- <div class="goods_list" id="discount_list" v-else-if="i === 1">
                <goods
                  v-for="(item, index) in navList[1].list"
                  :key="index"
                  :title="item.commodityName"
                  :goodsInfo="item"
                />
                <p
                  class="pull_up"
                  v-show="
                    !(
                      navList[1].error ||
                      navList[1].finished ||
                      navList[1].loading
                    )
                  "
                >
                  上拉 查看更多推荐商品 <svg-icon icon-class="home_more_icon" />
                </p>
                <div
                  v-show="navList[1].finished && navList[1].list.length"
                  class="end"
                >
                  <p @click="$router.push('/goodsList')">点击去查看所有商品</p>
                </div>
              </div> -->
              <div class="brand" v-show="i === 2">
                <van-grid
                  :column-num="4"
                  class="brand_list"
                  :border="false"
                  gutter="6px"
                >
                  <van-grid-item
                    v-for="item in navList[2].list"
                    :key="item.brandId"
                    :icon="item.logo"
                    @click="
                      $router.push({
                        path: '/brandDetail',
                        query: {
                          IId: item.id,
                          brandId: item.brandId,
                        },
                      })
                    "
                  />
                </van-grid>
                <router-link
                  v-show="navList[2].finished"
                  to="/brand"
                  class="end"
                  >点击去查看全部品牌</router-link
                >
              </div>
              <div class="store_list" v-show="i === 3">
                <div
                  class="list"
                  v-for="(item, index) in navList[3].list"
                  :key="index"
                >
                  <div class="list_top">
                    <div class="store_logo">
                      <img :src="item.storeLogo" alt="" />
                      <span>{{ item.storeName }}</span>
                    </div>
                    <span
                      class="to_store"
                      @click="$router.push(`/store?storeId=${item.storeId}`)"
                      >进店</span
                    >
                  </div>
                  <div
                    :class="item.storeCommodityItems.length < 1 ? '' : 'item'"
                  >
                    <div
                      class="item_sub"
                      v-for="(list, index1) in item.storeCommodityItems"
                      :key="index1"
                      @click="$router.push(`/goodsDetails?commId=${list.id}`)"
                    >
                      <img v-lazy="list.filePath" alt="" />
                      <span class="name">{{ list.commodityName }}</span>
                      <p class="price" v-if="list.goodsStatus === 1">
                        <span>￥</span
                        >{{
                          list.storeTastingActivityInfoEntity.price.toFixed(2)
                        }}
                      </p>
                      <p class="price" v-else-if="list.goodsStatus === 2">
                        <span>￥</span
                        >{{
                          list.querySecKillCommodityListRsp.retailSeckillPrice.toFixed(
                            2
                          )
                        }}
                      </p>
                      <p class="price" v-else-if="userType === 'PURCHASE'">
                        <span>￥</span>{{ list | tradePrice }}
                      </p>
                      <p class="price" v-else>
                        <span>￥</span>{{ list | retailPrice }}
                      </p>
                    </div>
                  </div>
                </div>
                <router-link
                  v-show="navList[3].finished"
                  to="/selectedShops"
                  class="end"
                  >点击去查看全部店铺</router-link
                >
              </div>
            </van-list>
          </van-tab>
        </van-tabs>
      </div>
    </van-pull-refresh>
    <div class="toLogin" v-if="!isLogin">
      <p>新用户首单专享低价商品，快快登录吧~</p>
      <button @click="$router.push('/login')">去登录</button>
    </div>
    <functionButton />
  </div>
</template>

<script>
import Goods from "@/components/Goods";
import Search from "@/components/Search";
import { mapState } from "vuex";
import {
  formatMsgTime,
  formatDate,
  preferential,
  localStorageSetItem,
  localStorageGetItem,
} from "@/utils/validate";
import { WechatSharing, JSSDKready } from "@/utils/sdk";

// import { getToken, setToken, removeToken } from "@/utils/auth";
import ColorThief from "colorthief";

export default {
  name: "home-page",
  components: { Goods, Search },
  computed: {
    ...mapState("user", [
      "isLogin",
      "tzInfo",
      "bank",
      "userInfo",
      "change",
      "userType",
    ]),
  },
  data() {
    return {
      show: false,
      search: "",
      searchShow: false,
      banner: [],
      LiveInfo: null,
      // news: "",
      tastePriceList: [],
      category: [],
      seckillList: [],
      secKillDay: 1,
      seckillInfo: {},
      seckillGoodsList: [],
      activity: [],

      brandShow: false,
      storeShow: false,
      isLoading: false,

      integralBannerShow: false,
      bankBanner: [],

      specialAreaList: [
        {
          n: "热销榜单",
          t: "大家都在买",
          c: "#FB4E10",
          goods: 0,
          href: "/hotList",
        },
        {
          n: "新品速递",
          t: "最近上新",
          c: "#2D87FF",
          goods: 0,
          href: "/specialArea/newProductExpress",
        },
      ],
      greatValueSpecial: {
        n: "超值特惠",
        goods: 0,
        href: "/specialArea/greatValueSpecial",
      },
      active: 0,
      navList: [
        {
          title: "推荐商品",
          list: [],
          pageNum: 1,
          pages: 1,
          loading: false,
          finished: false,
          error: false,
          load: this.getGoodsList,
        },
        {
          title: "疯狂折扣",
          list: [],
          pageNum: 1,
          pages: 1,
          loading: false,
          finished: false,
          error: false,
          load: this.getDiscountGoodsList,
        },
        {
          title: "推荐品牌",
          list: [],
          pageNum: 1,
          pages: 1,
          loading: false,
          finished: false,
          error: false,
          load: this.getQueryBrandList,
        },
        {
          title: "精选店铺",
          list: [],
          pageNum: 1,
          pages: 1,
          loading: false,
          finished: false,
          error: false,
          load: this.getStoreList,
        },
      ],
      topDistance: 1,
      bk: "",
      URLSchema: "",
    };
  },
  filters: {
    Time(val) {
      return formatMsgTime(val);
    },
    tradePrice(value) {
      return preferential(value, "tradePrice").price;
    },
    retailPrice(value) {
      return preferential(value, "retailPrice").price;
    },
  },
  watch: {
    integralBannerShow(val) {
      if (val) {
        let shareInfo = {
          title: "正品汇会员领积分啦",
          desc: "200元等值积分，全场通用，下单可抵扣",
          imgUrl: require("@/assets/images/logo.png"),
          link: window.location.hash,
        };
        WechatSharing(shareInfo);
      } else {
        WechatSharing();
      }
    },
  },
  created() {
    window.callJS = this.callJS;

    if (this.$route.query.integralBannerShow === "1")
      this.integralBannerShow = true;
    this.$store.commit("user/ShoppingCartSumChange", false);
    this.getBanner();
    this.getLiveBroadcastList();
    // this.getNews();
    this.getClassification();
    this.getHotSellingList();
    this.getNewProduct();
    this.getPreferential();
    this.getActivityList();

    // this.bank.forEach((item) => {
    //   if (item.bankId !== "0") this.bankBanner.push(item);
    // });
  },
  mounted() {},
  activated() {
    window.callJS = this.callJS;

    window.addEventListener("scroll", this.handleScrollx, true);
    if (this.$route.query.integralBannerShow === "1")
      this.integralBannerShow = true;
    if (this.change) {
      if (this.active) {
        this.navList[1].list = [];
        this.navList[1].pageNum = 1;
        this.navList[1].pages = 1;
        this.navList[1].loading = false;
        this.navList[1].finished = false;
        this.navList[1].error = false;
        this.$nextTick(function () {
          this.waterFall();
        });
      } else {
        this.navList[0].list = [];
        this.navList[0].pageNum = 1;
        this.navList[0].pages = 1;
        this.navList[0].loading = false;
        this.navList[0].finished = false;
        this.navList[0].error = false;
        this.$nextTick(function () {
          this.waterFall();
        });
      }
    }
    this.searchShow = false;
    this.search = "";

    this.bankBanner = [];
    this.handleScrollx();
    this.getTastePriceList();
    this.secKillDay = 1;
    this.querySecKillInfoListByNow();
    if (this.bk) this.bannerChange(0);
    setTimeout(() => {
      this.bank.forEach((item) => {
        if (item.bankId !== "0") this.bankBanner.push(item);
      });
    }, 500);
  },
  deactivated() {
    window.removeEventListener("scroll", this.handleScrollx, true);
  },
  methods: {
    handleScrollx() {
      this.topDistance =
        this.$refs.bannerBottom.getBoundingClientRect().top - 54;

      // console.log("滚动高度", window.pageYOffset)
    },
    getSearchShow(val) {
      this.search = val;
      this.searchShow = false;
    },
    //轮播图
    async getBanner() {
      const data = await this.$API.homePage.getBanner({
        object: {
          location: "1",
        },
        pageNum: 0,
        pageSize: 0,
      });
      this.banner = data.data.data;
      setTimeout(() => {
        let colorthief = new ColorThief();
        let img = document.querySelector(`#bannerImg${0}`);
        img.addEventListener("load", () => {
          this.bk = String(colorthief.getColor(img));
        });
      }, 100);
    },
    async getLiveBroadcastList() {
      const data = await this.$API.homePage.liveBroadcastList({
        object: {
          id: 0,
        },
        pageNum: 0,
        pageSize: 0,
      });
      if (!data.data.data) return;
      let openingTime = new Date(data.data.data.openingTime).getTime();
      let closingTime = new Date(data.data.data.closingTime).getTime();
      let time = new Date().getTime();
      if (!(time - openingTime > 0) || !(closingTime - time > 0)) {
        this.LiveInfo = null;
        return;
      }
      // JSSDKready()

      // if (typeof WeixinJSBridge == "undefined") {
      if (!localStorageGetItem("URLSchema")) {
        let obj = JSON.stringify({
          jump_wxa: {
            path: "pages/index/index",
            query: "",
          },
          expire_type: "",
        });
        await this.$API.user
          .generatescheme({
            object: obj,
          })
          .then((res) => {
            let data = JSON.parse(res.data.data);
            if (data.errcode) throw Error(data.errmsg);

            localStorageSetItem("URLSchema", data.openlink, 60 * 24 * 29);
            this.URLSchema = data.openlink;
          })
          .catch((e) => {
            // this.Toast.fail(`直播间跳转链接 ${e.message}`);
          });
      } else {
        this.URLSchema = localStorageGetItem("URLSchema");
      }
      // } else {
      // JSSDKready();
      // this.Toast("微信浏览器");
      // }

      this.LiveInfo = data.data.data;
      this.LiveInfo.items.forEach((goods) => {
        let img = goods.filePath.split(",");
        if (img[0].endsWith(".mp4")) {
          goods.filePath = img[1];
        } else {
          goods.filePath = img[0];
        }
      });
      this.$set(this.LiveInfo, "liveStatus", 0);
      if (time - this.LiveInfo.startTime > 0) this.LiveInfo.liveStatus = 1;
      let date = new Date(this.LiveInfo.startTime);
      let MM = date.getMonth() + 1; // 月
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate(); // 日
      d = d < 10 ? "0" + d : d;
      let h = date.getHours(); // 时
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes(); // 分
      m = m < 10 ? "0" + m : m;
      this.LiveInfo.startTime = `${MM}月${d}日 ${h}:${m}`;
    },
    //新人尝鲜列表
    async getTastePriceList() {
      const data = await this.$API.homePage.queryActivityList({
        object: {
          enableState: true,
          examineState: 1,
          state: "1",
          userId: this.userInfo ? this.userInfo.id : "",
        },
        pageNum: 0,
        pageSize: 0,
      });
      if (data.data.code === "UNKNOWN_ERROR") return;
      this.tastePriceList = data.data.data;
      this.tastePriceList.forEach((goods) => {
        let img = goods.filePath.split(",");
        if (img[0].endsWith(".mp4")) {
          goods.filePath = img[1];
        } else {
          goods.filePath = img[0];
        }
      });
    },
    //查询秒杀场次
    async querySecKillInfoListByNow() {
      const data = await this.$API.homePage.querySecKillInfoListByNow({
        object: {
          day: this.secKillDay,
        },
        pageNum: 0,
        pageSize: 0,
      });

      if (!data.data.data) return;
      if (!data.data.data.length && this.secKillDay < 2) {
        this.secKillDay++;
        await this.querySecKillInfoListByNow();
        return;
      }
      if (!data.data.data.length) return;
      this.seckillList = data.data.data;
      this.seckillInfo = this.seckillList.find((item) => {
        return item.seckillEnableState === 1 || item.seckillEnableState === 0;
      });
      if (this.seckillInfo === undefined)
        this.seckillInfo = this.seckillList[this.seckillList.length - 1];
      // console.log(formatDate(this.seckillInfo.seckillStartTime) + " 开始");
      // console.log(formatDate(this.seckillInfo.seckillEndTime) + " 结束");
      this.getseckillList();
    },
    //秒杀商品列表
    async getseckillList() {
      const data = await this.$API.homePage.querySecKillCommodityList({
        object: {
          seckillInfoId: this.seckillInfo.id,
        },
        pageNum: 0,
        pageSize: 0,
      });

      this.seckillGoodsList = data.data.data;
      this.seckillGoodsList.forEach((goods) => {
        let img = goods.filePath.split(",");
        if (img[0].endsWith(".mp4")) {
          goods.filePath = img[1];
        } else {
          goods.filePath = img[0];
        }
      });
    },
    //倒计时结束
    finish() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.secKillDay = 1;
        this.querySecKillInfoListByNow();
        this.navList[0].list = [];
        this.navList[0].pageNum = 0;
        this.navList[0].finished = false;
        this.navList[1].list = [];
        this.navList[1].pageNum = 1;
        this.navList[1].finished = false;
      }, 2000);
    },
    //获取新闻列表
    // async getNews() {
    //   const data = await this.$API.platform.News({
    //     object: {
    //       type: "XWZX",
    //     },
    //     pageNum: 0,
    //     pageSize: 0,
    //   });
    //   this.news = data.data.data;
    // },
    //获取分类
    async getClassification() {
      const data = await this.$API.platform.Classification({
        object: {
          enterpriseId: "",
        },
      });
      // 定义接收数据的空数组
      let result = [];
      // num 控制几个为一组
      let num = 10;
      for (let i = 0; i < data.data.data.categoriesCatalog.length; i += num) {
        result.push(data.data.data.categoriesCatalog.slice(i, i + num));
      }
      this.category = result;
    },
    //热销
    async getHotSellingList() {
      const data = await this.$API.hotList.HotSaleActivityList({
        object: {
          categoryId: "",
        },
      });
      if (!data.data.data.length) {
        this.specialAreaList[0].goods = data.data.data;
        return;
      }
      data.data.data.forEach((goods) => {
        let img = goods.filePath.split(",");
        if (img[0].endsWith(".mp4")) {
          goods.filePath = img[1];
        } else {
          goods.filePath = img[0];
        }
      });
      // 定义接收数据的空数组
      let result = [];
      // num 控制几个为一组
      let num = 2;
      for (let i = 0; i < data.data.data.length; i += num) {
        result.push(
          data.data.data.slice(i, i + num).map((i) => {
            return i.filePath;
          })
        );
      }

      // console.log(result);
      this.specialAreaList[0].goods = result;
    },
    //新品
    async getNewProduct() {
      const data = await this.$API.homePage.CommList({
        object: {
          newProducts: true,
          userId: this.tzInfo ? this.tzInfo.id : "",
        },
        pageNum: 0,
        pageSize: 0,
      });
      if (!data.data.data.length) {
        this.specialAreaList[1].goods = data.data.data;
        return;
      }

      data.data.data.forEach((goods) => {
        let img = goods.filePath.split(",");
        if (img[0].endsWith(".mp4")) {
          goods.filePath = img[1];
        } else {
          goods.filePath = img[0];
        }
      });

      // 定义接收数据的空数组
      let result = [];
      // num 控制几个为一组
      let num = 2;
      for (let i = 0; i < data.data.data.length; i += num) {
        result.push(
          data.data.data.slice(i, i + num).map((i) => {
            return i.filePath;
          })
        );
      }
      this.specialAreaList[1].goods = result;
    },
    //优惠
    async getPreferential() {
      const data = await this.$API.homePage.CommList({
        object: {
          deduction: 0,
          userId: this.tzInfo ? this.tzInfo.id : "",
        },
        pageNum: 0,
        pageSize: 0,
      });
      if (!data.data.data.length) {
        this.greatValueSpecial.goods = data.data.data;
        return;
      }

      this.greatValueSpecial.goods = data.data.data.map((goods) => {
        let img = goods.filePath.split(",");
        if (img[0].endsWith(".mp4")) {
          return (goods.filePath = img[1]);
        } else {
          return (goods.filePath = img[0]);
        }
      });

      // // 定义接收数据的空数组
      // let result = [];
      // // num 控制几个为一组
      // let num = 2;
      // for (let i = 0; i < data.data.data.length; i += num) {
      //   result.push(
      //     data.data.data.slice(i, i + num).map((i) => {
      //       return i.filePath;
      //     })
      //   );
      // }
      // this.greatValueSpecial.goods = result;
    },
    //获取活动
    async getActivityList() {
      const data = await this.$API.homePage.getActivityList({
        object: {
          freeShipping: this.tzInfo ? this.tzInfo.userEntity.freeShipping : "",
          location: "1",
        },
        pageNum: 1,
        pageSize: 4,
      });
      if (!data.data.data) return;
      this.activity = data.data.data;
      this.activity.forEach((item) => {
        item.platformPic = item.platformPic.split(",");
      });
      // console.log(this.activity);
    },

    //轮播图跳转
    skip(val) {
      switch (val.urlType) {
        case 1:
          this.$router.push({
            path: "/goodsDetails",
            query: {
              commId: val.foreignKey,
            },
          });
          break;
        case 2:
          this.$router.push({
            path: `/store/homePage`,
            query: {
              storeId: val.storeId,
            },
          });
          break;
        case 3:
          this.$router.push({
            path: `/goodsList`,
            query: {
              brandId: val.foreignKey,
            },
          });
          break;
        default:
          break;
      }
    },
    //轮播图切换
    bannerChange(i) {
      let colorthief = new ColorThief();
      let img = document.querySelector(`#bannerImg${i}`);
      this.bk = String(colorthief.getColor(img));
    },
    //下滑加载
    onLoad(i) {
      switch (this.active) {
        case 0:
          this.getGoodsList();
          break;
        case 1:
          this.getDiscountGoodsList();
          break;
        default:
          break;
      }
    },
    //推荐商品
    async getGoodsList() {
      await this.$API.homePage
        .CommList({
          object: {
            brandId: "",
            ceCategoryId: null,
            location: "4",
            order: "desc",
            sortColumn: "",
            memberId: this.isLogin ? this.userInfo.id : undefined,
            userId: this.tzInfo ? this.tzInfo.id : "",
          },
          pageNum: this.navList[0].pageNum,
          pageSize: 4,
        })
        .then((res) => {
          if (res.data.code === "UNKNOWN_ERROR") throw Error(res.data.msg);

          res.data.data.forEach((a) => {
            a.filePath = a.filePath.split(",")[0].endsWith(".mp4")
              ? a.filePath.split(",")[1]
              : a.filePath.split(",")[0];
            if (
              a.orderCartListItems.length &&
              !a.orderCartListItems[0].isDelete
            ) {
              a.quantity = a.orderCartListItems[0].quantity;
            } else {
              a.quantity = 0;
            }
            this.navList[0].list.push(a);
          });

          this.navList[0].pages = res.data.pages;
          if (this.navList[0].pageNum >= this.navList[0].pages) {
            this.navList[0].finished = true;
          } else {
            this.navList[0].pageNum++;
          }
          this.navList[0].loading = false;
        })
        .catch((err) => {
          console.error("推荐商品: " + err.message);
          this.navList[0].error = true;
          this.navList[0].loading = false;
        });
      this.$store.commit("user/ShoppingCartSumChange", false);
      this.$nextTick(function () {
        this.waterFall();
      });
    },
    //疯狂折扣
    async getDiscountGoodsList() {
      await this.$API.homePage
        .CommList({
          object: {
            discount: true,
            order: "desc",
            memberId: this.isLogin ? this.userInfo.id : undefined,
            userId: this.tzInfo ? this.tzInfo.id : "",
          },
          pageNum: this.navList[1].pageNum,
          pageSize: 4,
        })
        .then((res) => {
          if (res.data.code === "UNKNOWN_ERROR") throw Error(res.data.msg);
          res.data.data.forEach((a) => {
            a.filePath = a.filePath.split(",")[0].endsWith(".mp4")
              ? a.filePath.split(",")[1]
              : a.filePath.split(",")[0];
            if (
              a.orderCartListItems.length &&
              !a.orderCartListItems[0].isDelete
            ) {
              a.quantity = a.orderCartListItems[0].quantity;
            } else {
              a.quantity = 0;
            }
            this.navList[1].list.push(a);
          });

          this.navList[1].pages = res.data.pages;
          if (this.navList[1].pageNum >= this.navList[1].pages) {
            this.navList[1].finished = true;
          } else {
            this.navList[1].pageNum++;
          }
          this.navList[1].loading = false;
        })
        .catch((err) => {
          console.error("疯狂折扣: " + err.message);
          this.navList[1].error = true;
          this.navList[1].loading = false;
        });

      this.$store.commit("user/ShoppingCartSumChange", false);
      this.$nextTick(function () {
        this.waterFall();
      });
    },
    //推荐品牌
    async getQueryBrandList() {
      await this.$API.homePage
        .QueryBrandList({
          object: {
            location: "2",
          },
          pageNum: this.navList[2].pageNum,
          pageSize: 8,
        })
        .then((res) => {
          if (res.data.code === "UNKNOWN_ERROR") throw Error(res.data.msg);
          res.data.data.forEach((e) => {
            this.navList[2].list.push(e);
          });
          this.navList[2].pages = res.data.pages;
          if (this.navList[2].pageNum >= this.navList[2].pages) {
            this.navList[2].finished = true;
          } else {
            this.navList[2].pageNum++;
          }
          this.navList[2].loading = false;
        })
        .catch((err) => {
          console.error("推荐品牌: " + err.message);
          this.navList[2].error = true;
          this.navList[2].loading = false;
        });
    },
    //精选店铺
    async getStoreList() {
      await this.$API.homePage
        .storeList({
          object: {
            enterpriseId: "",
            location: "5",
            memberId: this.isLogin ? this.userInfo.id : undefined,
            userId: this.tzInfo ? this.tzInfo.id : "",
          },
          pageNum: this.navList[3].pageNum,
          pageSize: 4,
        })
        .then((res) => {
          if (res.data.code === "UNKNOWN_ERROR") throw Error(res.data.msg);
          res.data.data.forEach((item) => {
            item.storeCommodityItems.forEach((item1) => {
              item1.filePath = item1.filePath.split(",")[0].endsWith(".mp4")
                ? item1.filePath.split(",")[1]
                : item1.filePath.split(",")[0];

              if (
                item.newUser &&
                item.storeTastingActivityInfoEntity &&
                item1.querySecKillCommodityListRsp
              ) {
                if (
                  item1.storeTastingActivityInfoEntity.price <=
                  item1.querySecKillCommodityListRsp.retailSeckillPrice
                ) {
                  item1.goodsStatus = 1;
                } else {
                  item1.goodsStatus = 2;
                }
              } else if (item.newUser && item1.storeTastingActivityInfoEntity) {
                item1.goodsStatus = 1;
              } else if (item1.querySecKillCommodityListRsp) {
                item1.goodsStatus = 2;
              } else {
                item1.goodsStatus = 0;
              }
            });
          });
          console.log(res.data.data);
          res.data.data.forEach((e) => {
            this.navList[3].list.push(e);
          });

          this.navList[3].pages = res.data.pages;
          if (this.navList[3].pageNum >= this.navList[3].pages) {
            this.navList[3].finished = true;
          } else {
            this.navList[3].pageNum++;
          }
          this.navList[3].loading = false;
        })
        .catch((err) => {
          console.error("精选店铺: " + err.message);
          this.navList[3].error = true;
          this.navList[3].loading = false;
        });
    },
    //下拉刷新
    onRefresh() {
      setTimeout(() => {
        // Toast("刷新成功");
        this.getBanner();
        this.getLiveBroadcastList();
        this.getClassification();
        this.getTastePriceList();
        this.secKillDay = 1;
        this.querySecKillInfoListByNow();
        this.getHotSellingList();
        this.getNewProduct();
        this.getPreferential();
        this.getActivityList();
        document.getElementById("goods_list") &&
          (document.getElementById("goods_list").style.height = "auto");
        this.navList.forEach((e) => {
          e.list = [];
          e.pageNum = 1;
          e.finished = false;
          e.loading = false;
          e.finished = false;
          e.error = false;
        });

        this.isLoading = false;
      }, 1000);
    },
    //便签切换事件
    tabChange(i) {
      if (
        (i === 0 && this.navList[0].list.length) ||
        (i === 1 && this.navList[1].list.length)
      ) {
        this.$nextTick(function () {
          this.waterFall();
        });
      }
    },
    //跳转分类
    goCategory(list) {
      if (sessionStorage.getItem("userUuid")) {
        var id = sessionStorage.getItem("userUuid");
      }
      this.$router.push({
        path: "classification",
        query: {
          categoryId: list.categoryId,
          id: id ? id : undefined,
        },
      });
    },
    //银行积分政策
    toPolicy(item) {
      sessionStorage.setItem("policy", item.policyIntegral);
      this.$router.push("/policyOfPoints");
    },
    //领取积分
    RegisteredBonusIntegral() {
      if (!this.isLogin) {
        this.integralBannerShow = false;
        this.$router.push({
          path: "/login",
          query: {
            redirect_uri: this.$route.fullPath,
          },
        });
        return;
      }
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(async () => {
        await this.$API.user
          .RegisteredBonusState({
            object: {
              id: this.userInfo.id,
            },
          })
          .then(async (res) => {
            if (!res.data.data) {
              await this.$API.user
                .RegisteredBonusIntegral({
                  object: {
                    userId: this.userInfo.id,
                  },
                })
                .then((res) => {
                  console.log(res);
                  this.$store.dispatch("user/getCusBankFilePathList");
                  this.Toast.success({
                    message: "领取成功",
                    className: "integral_tips",
                  });
                });
            } else {
              this.Toast.fail({
                message: "已经领取过了",
                className: "integral_tips",
              });
            }
          });
      }, 500);
    },
    //查看积分
    viewIntegral() {
      if (!this.isLogin) {
        this.integralBannerShow = false;
        this.$router.push({
          path: "/login",
          query: {
            redirect_uri: this.$route.fullPath,
          },
        });
        return;
      }
      if (
        this.bank.findIndex((item) => {
          return item.bankId === "0";
        }) >= 0
      ) {
        this.integralBannerShow = false;
        this.$router.push("/userData/integral?bankId=0&name=正品汇平台积分");
      } else {
        this.integralBannerShow = false;
        this.$router.push("/userData/wallet");
      }
    },
    //切换自提点地址
    switchAddress() {
      let info;
      if (this.tzInfo) {
        info = {
          type: 2,
          data: {
            id: this.tzInfo.id,
            telNum: this.tzInfo.userEntity.telNum,
            name: this.tzInfo.userEntity.name,
            actualName: this.tzInfo.userEntity.actualName,
            profilePicture: this.tzInfo.userDetailEntity.profilePicture,
            getGoodsAddress: this.tzInfo.userDetailEntity.getGoodsAddress,
            detailAddress: this.tzInfo.userDetailEntity.detailAddress,
            getGoodsPicture: this.tzInfo.userDetailEntity.getGoodsPicture,
            latitudeValue: this.tzInfo.userDetailEntity.latitudeValue,
            longitudeValue: this.tzInfo.userDetailEntity.longitudeValue,
            province: this.tzInfo.userDetailEntity.province,
            city: this.tzInfo.userDetailEntity.city,
            county: this.tzInfo.userDetailEntity.county,
            getGoodsName: this.tzInfo.userDetailEntity.getGoodsName,
            distance: sessionStorage.getItem("distance")
              ? Number(sessionStorage.getItem("distance"))
              : 0,
          },
        };
      } else {
        info = {
          type: 2,
          data: {
            id: "",
            telNum: "",
            name: "",
            actualName: "",
            profilePicture: "",
            getGoodsAddress: "",
            detailAddress: "",
            getGoodsPicture: "",
            latitudeValue: "",
            longitudeValue: "",
            province: "",
            city: "",
            county: "",
            getGoodsName: "",
            distance: "",
          },
        };
      }

      console.log(info);
      Android.callAndroid(JSON.stringify(info));
    },
    //切换地址更新团长信息
    async callJS(val) {
      let info = JSON.parse(val);
      if (info.type === 1) {
        // setToken(info.data.token);
      } else if (info.type === 2) {
        console.log("切换自提点");
        sessionStorage.setItem("userUuid", info.data.id);
        sessionStorage.setItem("distance", info.data.distance);

        await this.$API.login
          .getUserData({
            object: {
              id: info.data.id,
            },
          })
          .then((res) => {
            this.$store.commit("user/setTzInfo", res.data.data.userInfo);
            // this.$router.replace({
            //   path: "homePage",
            //   query: {
            //     id: info.data.id,
            //   },
            // });
            this.$forceUpdate();
            console.log(location.href);
          })
          .catch((err) => {
            console.log(err);
            this.Toast.fail("获取店长信息失败");
          });
      }
    },
    waterFall() {
      // 1. 设置container盒子的宽度
      //      注意：浏览器的可视区域的宽度 / 一个item元素的宽度 = 一行的排列的元素的个数
      let container = document.getElementById("goods_list");
      let item = document.getElementsByClassName("goods_item_box");

      // let item = [];
      // for (const node of container.children) {
      //   if (node.className === "goods_item_box") {
      //     item.push(node);
      //   }
      // }
      if (!item.length) {
        if (container) container.style.height = "auto";
        return;
      }

      //获取元素的宽度(含border，padding)
      let width = item[0].offsetWidth;
      //计算出浏览器窗口的宽度
      let clientWidth = document.documentElement.clientWidth;
      //计算出应该放几列（向下取整）
      // let columnCount = Math.floor(clientWidth / width);
      let columnCount = 2;
      //设置容器（父盒子）的宽度
      // container.style.width = columnCount * width + "px";
      let left;
      let height;
      height = left = container.offsetWidth - width * columnCount; //宽度间隔

      // 2.设置每一个item元素的排列位置
      //  第一行整体的top值都是0 后面的依次找上一行高度最小的容器，在它下面进行排列
      let hrr = [];
      for (let i = 0; i < item.length; i++) {
        //定位第一行的图片
        if (i < columnCount) {
          item[i].style.top = "0px";
          if (i) {
            item[i].style.left = i * width + left + "px";
          } else {
            item[i].style.left = i * width + "px";
          }
          hrr.push(item[i].offsetHeight + height);
        } else {
          //第一行之后的
          //选择总高度最小的列
          let min = Math.min(...hrr);
          let index = hrr.indexOf(min);
          //将每个元素定位到当前总高度最小的列下
          //index为0在左边 为1在右边 2以此类推
          item[i].style.top = min + "px";
          if (index) {
            item[i].style.left = index * width + left + "px";
          } else {
            item[i].style.left = index * width + "px";
          }
          //当前定位的元素加入该列
          hrr[index] += item[i].offsetHeight + height;
        }
      }
      if (hrr.length < 2) {
        container.style.height = `${hrr[0]}px`;
        return;
      }
      if (hrr[0] >= hrr[1]) {
        container.style.height = `${hrr[0]}px`;
      } else {
        container.style.height = `${hrr[1]}px`;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScrollx, true);
  },
};
</script>

<style lang="scss" scoped>
.home_page {
  position: relative;
  bottom: 50px;
  padding-top: 48px;
  ::v-deep .tzInfo-overlay {
    z-index: 99999 !important;
    background-color: rgba(0, 0, 0, 0.4);
  }
  ::v-deep .van-popup {
    z-index: 100000 !important;

    .more_info {
      display: flex;
      padding: 15px;
      img {
        width: 60px;
        height: 60px;
        margin-right: 15px;
        border-radius: 50%;
      }
      .user_info {
        h3 {
          // font-size: 16px;
          margin-bottom: 6px;
        }
        p {
          line-height: 18px;
        }
      }
    }
  }
  .info {
    padding: 12px 15px 0;
    background: #fff;
    display: flex;
    color: #333333;
    justify-content: space-between;
    transition: 0.3s all;
    // background: #1d4b89;
    span:nth-child(1) {
      // white-space: nowrap;
      // text-overflow: ellipsis;
      // overflow: hidden;
      font-size: 16px;
      font-weight: bold;
      color: #fefefe;
      .svg-icon {
        font-size: 10px;
        vertical-align: unset;
        margin-left: 4px;
      }
    }

    .switch {
      padding-left: 20px;
      color: #fefefe;
      flex-shrink: 0;

      font-size: 16px;
      .svg-icon {
        color: #fefefe;
        margin-right: 2px;
      }
    }
  }
  // ::v-deep .van-sticky {
  //   background: #fff;

  //   .location {
  //     display: flex;
  //     align-items: center;
  //     p {
  //       // margin-right: 4px;
  //       // margin-left: 6px;
  //       margin: 0 4px;
  //       font-family: PingFang SC;
  //       font-weight: bold;
  //       color: #333333;
  //     }
  //     .Check {
  //       font-size: 8px;
  //     }
  //   }
  ::v-deep .search .van-sticky--fixed {
    max-width: 500px;
    margin: 0 auto;
  }
  .van-search {
    // background: #1d4b89;
    padding: 12px 15px;
    transition: 0.3s all;
    .van-search__content {
      // margin-left: 24px;
      height: 30px;
      background-color: #f2f2f2;
      .van-cell {
        padding: 0;
        align-items: center;
        .van-field__left-icon {
          margin-right: 8px;
        }
        .van-field__control {
          font-size: 12px;
        }
        .van-field__control::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #a8a8a8;
        }
        .van-field__control:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #a8a8a8;
        }
        .van-field__control::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #a8a8a8;
        }
        .van-field__control:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #a8a8a8;
        }
      }
    }
    .van-search__action:active {
      background: none;
    }
    .van-search__action {
      padding: 0 0 0 16px;
      line-height: 22px;
      .svg-icon {
        font-size: 22px;
        color: #fff;
      }
    }
  }
  .van-search2 {
    background: #f5f6fa;
    .van-search__content {
      background-color: #fff;
    }
    .van-search__action .svg-icon {
      color: #1d4b89;
    }
  }
  // }
  .van-pull-refresh {
    .p_0_15 {
      padding: 0 15px;
    }
    .background_top {
      position: relative;
      transition: 0.3s background;
      // background: linear-gradient(
      //   180deg,
      //   #1d4b89 0%,
      //   #fff 40%,
      //   #fff 70%,
      //   #f5f6fa 100%
      // );
      .banner {
        height: 155px;
        border-radius: 8px;
        margin-bottom: 10px;
        .van-swipe-item {
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        ::v-deep .van-swipe__indicators {
          bottom: 6px;
          .van-swipe__indicator {
            width: 8px;
            height: 4px;
            background: #ffffff;
            border-radius: 4px;
            opacity: 0.5;
          }
          .van-swipe__indicator--active {
            opacity: 1;
          }
        }
      }
      .live_broadcast {
        background: linear-gradient(180deg, #f8dce8 0%, #ffffff 100%);
        border-radius: 10px;
        margin-bottom: 13px;
        position: relative;
        .title {
          display: flex;
          align-items: center;
          padding: 12px;
          h5 {
            font-size: 16px;
            color: #333333;
            margin-right: 8px;
          }
          span {
            font-size: 12px;
            color: #f91c12;
            flex: 1;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding-right: 6px;
          }
          a {
            color: #666666;
            .svg-icon {
              font-size: 10px;
              vertical-align: unset;
              margin-left: 2px;
            }
          }
        }
        .content {
          display: flex;
          padding: 0 12px 0;
          overflow: scroll;
          .tips {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-right: 16px;
            padding-bottom: 19px;
            img:nth-child(1) {
              margin: 10px 0 12px 0;
            }
            img:nth-child(2) {
              width: 34px;
            }
            span {
              background: #ffe9f2;
              color: #ff5770;
              border-radius: 12px;
              padding: 2px 11px;
            }
          }
          .goods {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 10px;
            img {
              width: 80px;
              height: 80px;
              border-radius: 10px;
            }
            .price {
              margin: 12px 0 4px 0;
              background: #fb4e10;
              border-radius: 12px;
              display: inline-block;
              color: #fff;
              padding: 4px 8px;
              font-weight: bold;
              span {
                font-size: 12px;
                font-weight: normal;
              }
            }
            .original_price {
              display: flex;
              align-items: center;
              .svg-icon {
                font-size: 24px;
              }
              span {
                font-size: 12px;
                color: #999999;
                text-decoration: line-through;
              }
            }
          }
        }
        .content::-webkit-scrollbar {
          display: none;
        }
        .URLSchema {
          position: absolute;
          top: 0;
          width: 100%;
          bottom: 0;
          // background: red;
        }
      }
      .taste_price {
        background: linear-gradient(180deg, #f9231a 0%, #fb4e10 100%);
        border-radius: 10px;
        margin-bottom: 6px;
        // display: none;
        color: #fff;
        .bg {
          display: block;
          width: 100%;
        }
        .title {
          background: #fff;
          padding: 9px 12px;
          line-height: 22px;
          color: #ff2f26;
          border-radius: 0 0 20px 20px;
          margin-bottom: 24px;
          display: flex;
          align-items: center;
          h5 {
            font-size: 16px;
            margin-right: 8px;
          }
          span {
            font-size: 12px;
            flex: 1;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding-right: 6px;
          }
          a {
            color: #ff2f26;
            .svg-icon {
              font-size: 10px;
              vertical-align: unset;
              margin-left: 2px;
            }
          }
        }
        .content {
          padding-bottom: 12px;
          overflow-x: scroll;
          padding-right: 12px;
          display: flex;
          .goods {
            margin-left: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            // width: 80px;
            img {
              width: 80px;
              height: 80px;
              border-radius: 10px;
              object-fit: cover;
              vertical-align: top;
            }
            .old_price {
              color: #fff;
              margin-top: 6px;
              text-decoration: line-through;
            }
            .price {
              background: #fff;
              color: #f91c12;
              font-size: 18px;
              font-weight: 800;
              margin-top: 6px;
              padding: 0 8px;
              border-radius: 10px;
              span {
                font-size: 12px;
              }
            }
          }
        }
        .content::-webkit-scrollbar {
          display: none;
        }
      }

      ::v-deep .category {
        margin-bottom: 18px;
        .van-grid {
          .van-grid-item {
            margin-top: 14px !important;
            .van-grid-item__content {
              padding: 0;
              background-color: transparent;

              .van-icon img {
                border-radius: 50%;
              }
              .van-grid-item__text {
                color: #666666;
                margin-top: 8px;
              }
            }
            .van-grid-item__content--surround::after {
              display: none;
            }
          }
        }
        .van-swipe__indicators {
          bottom: 0px;
          .van-swipe__indicator {
            width: 4px;
            height: 4px;
            background: #999999;
            transition: 0.2s width;
          }
          .van-swipe__indicator--active {
            width: 16px;
            height: 4px;
            background: #1d4b89;
            border-radius: 2px 2px 2px 2px;
          }
        }
      }
      .time_limited_seckill {
        background: linear-gradient(180deg, #e2f4ee 0%, #ffffff 100%);
        border-radius: 10px;
        margin-bottom: 12px;

        .title {
          padding: 12px;
          line-height: 22px;
          overflow: hidden;
          p:first-child {
            float: left;
            font-size: 16px;
            font-weight: 800;
            color: #333333;
          }
          span:nth-child(2) {
            float: left;
            margin-left: 8px;
            font-size: 12px;
            font-weight: 400;
            color: #fb4e10;
          }
          .countdown {
            display: inline-block;
            background-image: url("../../assets/images/home_xsms_bg.png");
            background-size: 100% 100%;
            font-family: Roboto-Regular, Roboto;
            color: #ffffff;
            padding: 0 10px;
            margin-left: 4px;
          }
          a:last-child {
            float: right;
            color: #666666;
            .svg-icon {
              font-size: 10px;
              vertical-align: unset;
              margin-left: 2px;
            }
          }
        }
        .content {
          padding-bottom: 6px;
          overflow-x: scroll;
          display: flex;
          padding-right: 12px;
          .van-empty {
            margin: 0 auto;
            padding: 10px 0;
          }
          .goods {
            margin-left: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              width: 80px;
              height: 80px;
              border-radius: 10px;
            }
            .price {
              margin: 12px 0 4px 0;
              background: #fb4e10;
              border-radius: 12px;
              display: inline-block;
              color: #fff;
              padding: 4px 8px;
              font-weight: bold;
              span {
                font-size: 12px;
                font-weight: normal;
              }
            }
            .original_price {
              display: flex;
              align-items: center;
              .svg-icon {
                font-size: 24px;
              }
              span {
                font-size: 12px;
                color: #999999;
                text-decoration: line-through;
              }
            }
          }
        }
        .content::-webkit-scrollbar {
          display: none;
        }
      }
    }
    .background_bottom {
      background: #f5f6fa;
      .bank_banner {
        margin-bottom: 12px;
        ::v-deep .van-swipe__track {
          align-items: center;
        }
        .van-swipe-item {
          img {
            width: 100%;
            border-radius: 999999px;
            display: block;
          }
        }
        ::v-deep .van-swipe__indicators {
          bottom: 2px;
          .van-swipe__indicator {
            width: 8px;
            height: 4px;
            background: #f5f5f5;
            border-radius: 4px 4px;
            opacity: 0.5;
          }
          .van-swipe__indicator--active {
            opacity: 1;
          }
        }
      }
      // .integral_banner {
      //   margin-top: 16px;
      //   padding: 0 15px;
      //   // height: 105px;
      //   img {
      //     width: 100%;
      //     height: 100%;
      //   }
      .van-overlay {
        background: rgba(51, 51, 51, 0.39);
        > img {
          width: 284px;
          height: 454px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -65%);
        }
        .wrapper {
          position: absolute;
          left: 50%;
          top: 50%;
          text-align: center;
          transform: translate(-50%, -65%);
          width: 100%;
          height: 435px;
          img {
            width: 120px;
            height: 120px;
            display: block;
            margin: 120px auto 0;
          }
          p:nth-child(2) {
            font-size: 20px;
            line-height: 20px;
            font-family: Adobe Heiti Std;
          }

          p:nth-child(3) {
            font-size: 14px;
            margin-top: 4px;
            font-family: Adobe Heiti Std;
            color: #666666;
          }
          > div {
            margin-top: 70px;
            button {
              width: 110px;
              height: 42px;
              background: linear-gradient(0deg, #fdc606, #f9dd5c);
              border-radius: 20px;
              border: none;
              font-size: 18px;
              font-family: Adobe Heiti Std;
              color: #ffffff;
              margin: 0 5px;
            }
          }
        }
        .svg-icon {
          position: absolute;
          left: 50%;
          top: 76%;
          transform: translate(-50%, 0);
          font-size: 32px;
        }
      }
      // }
      .special_area {
        display: flex;
        flex-wrap: wrap;
        border-radius: 10px;
        background: #ffffff;
        margin-bottom: 12px;
        > div {
          width: 50%;
          padding: 12px 12px 9px;
        }
        .box {
          min-height: 120px;
          position: relative;
          .title {
            margin-bottom: 8px;
            span:nth-child(1) {
              font-size: 16px;
              font-weight: 800;
              color: #333333;
              line-height: 22px;
            }
            span:nth-child(2) {
              font-size: 12px;
              margin-left: 8px;
            }
          }
          .van-empty {
            display: flex;
          }
          .van-loading {
            text-align: center;
            margin-top: 20px;
          }
          ::v-deep .van-swipe__track {
            align-items: center;
          }

          .van-swipe-item {
            display: flex;
            // align-items: center;
            div {
              width: 50%;
              border-radius: 10px;
            }
            img {
              width: 100%;
              height: 100%;
              border-radius: 10px;

              object-fit: cover;
              // height: 70px;
            }
            div:nth-child(1) {
              padding-right: 3px;
            }
            div:nth-child(2) {
              padding-left: 3px;
              text-align: right;
            }
          }
          .van-empty {
            padding: 0;
          }
        }
        .box:nth-child(1) {
          border-right: 1px solid #e9e9e9;
          border-bottom: 1px solid #e9e9e9;
        }
        .box:nth-child(2) {
          border-bottom: 1px solid #e9e9e9;
        }
        .box:nth-child(3) {
          border-right: 1px solid #e9e9e9;
        }
        .sign_in {
          display: flex;
          justify-content: space-between;
          > div {
            width: 49%;
            .title {
              font-size: 16px;
              font-weight: 800;
              color: #333333;
              line-height: 22px;
              margin-bottom: 10px;
              display: block;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 10px;
              display: block;
            }
          }

          .greatValueSpecial_box {
            ::v-deep .van-swipe {
              .van-swipe__track {
                align-items: center;
              }
            }
          }
          .sign_in_box {
            display: flex;
            flex-direction: column;
            > div {
              height: 100%;
            }
          }
        }
      }
      .activity {
        .title {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          p {
            font-size: 16px;
            color: #333333;
            font-weight: 800;
            span {
              font-weight: normal;
              font-size: 12px;
              color: #999999;
              margin-left: 8px;
            }
          }
          a {
            color: #666666;
            .svg-icon {
              font-size: 10px;
              vertical-align: unset;
            }
          }
        }
        .content {
          // height: 130px;
          overflow-x: scroll;
          display: flex;
          padding-bottom: 10px;
          img {
            flex-shrink: 0;
            border-radius: 10px;
            // width: 280px;
            height: 130px;
            margin-right: 10px;
          }
          img:last-child {
            margin-right: 0;
          }
        }
        .content::-webkit-scrollbar {
          display: none;
        }
      }
      ::v-deep .van-tabs {
        .van-sticky--fixed {
          left: 15px;
          right: 15px;
          max-width: 470px;
          margin: 0 auto;
          .van-tab::after {
            content: "" !important;
          }
          .van-tabs__nav {
            padding: 0;
          }
          .van-tabs__wrap {
            height: 40px;
          }
          .van-tab {
            align-items: center;
            padding: 0;
          }
          .van-tabs__line {
            height: 3px !important;
            bottom: 4px;
          }
        }
        .van-tabs__nav {
          background: #f5f6fa;
        }
        .van-tab {
          align-items: normal;
          padding-top: 4px;
        }
        .van-tab__text {
          font-size: 16px;
          font-weight: 800;
        }
        .van-tabs__line {
          border-radius: 4px !important;
        }
        .van-tab::after {
          position: absolute;
          bottom: 0;
          width: 64px;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          border-radius: 4px;
          z-index: 2;
          color: #999999;
          transition: 0.3s all;
        }
        .van-tab--active::after {
          color: #fff;
        }
        .van-tab:nth-child(1)::after {
          content: "为你推荐";
        }
        .van-tab:nth-child(2)::after {
          content: "疯抢ing";
        }
        .van-tab:nth-child(3)::after {
          content: "甄选优品";
        }
        .van-tab:nth-child(4)::after {
          content: "发现好店";
        }

        .goods_list {
          width: 100%;
          position: relative;
          margin-top: 12px;
          margin-bottom: 30px;
          .pull_up {
            position: absolute;
            bottom: -15px;
            left: 0;
            right: 0;
            display: flex;
            font-size: 12px;
            color: #999;
            justify-content: center;
            align-items: center;
            .svg-icon {
              color: #999;
              font-size: 8px;
              margin-left: 4px;
              transform: rotate(90deg);
            }
          }
          .end {
            position: absolute;
            bottom: -20px;
            left: 0;
            right: 0;
            text-align: center;
            line-height: 24px;
            color: #999999;
          }
        }
        .brand {
          padding-bottom: 10px;
          .brand_list {
            padding: 10px 0 !important;
            .van-grid-item {
              .van-grid-item__content {
                border-radius: 6px;
                padding: 0;
                .van-icon {
                  width: 100%;
                  img {
                    width: 100%;
                    height: 50px;
                    border-radius: 6px;
                  }
                }
              }
            }
          }
          .end {
            display: block;
            text-align: center;
            line-height: 24px;
            color: #999999;
          }
        }

        .store_list {
          padding: 10px 0;
          .list {
            background: #fff;
            padding: 10px;
            margin-bottom: 8px;
            border-radius: 6px;
          }
          .list_top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .to_store {
              height: 20px;
              line-height: 20px;
              color: #fff;
              background: #009a4d;
              padding: 0 10px;
              border-radius: 20px;
            }
          }
          .store_logo {
            display: flex;
            align-items: center;
            img {
              width: 20px;
              height: 20px;
              margin-right: 12px;
            }
          }
          .item {
            display: flex;
            overflow: auto;
            margin-top: 12px;
            .item_sub {
              padding: 0 6px;
              text-align: center;
            }
            .item_sub:first-child {
              padding-left: 0;
            }
            .item_sub:last-child {
              padding-right: 0;
            }
            img {
              width: 80px;
              height: 80px;
              border-radius: 6px;
            }
            p {
              margin-top: 4px;
              color: #fb4e10;
            }
            span.name {
              display: block;
              width: 80px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              color: #333;
            }
          }
          .item::-webkit-scrollbar {
            display: none;
          }
          .end {
            display: block;
            text-align: center;
            line-height: 24px;
            color: #999999;
          }
        }
      }
    }
  }

  .toLogin {
    position: fixed;
    bottom: 60px;
    background: rgba($color: #000000, $alpha: 0.7);
    left: 15px;
    right: 15px;
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 12px;
    border-radius: 70px;
    z-index: 100;
    box-shadow: 0 2px 6px 1px rgba(51, 51, 51, 0.4);
    p {
      padding: 0 4px;
      color: #fff;
      font-size: 13px;
    }
    button {
      padding: 6px 10px;
      font-size: 12px;
      border: none;
      border-radius: 14px;
      background: #fff;
      flex-shrink: 0;
    }
  }
}
</style>
<style lang="scss">
.integral_tips {
  z-index: 100000 !important;
}
</style>
